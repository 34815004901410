<template>
  <Loader :show="loading"/>
  <div class="evento-info">
    <div style="text-align: center;">
      <img src="/assets/img/logo.jpg" height="100">
    </div>
    <p><span><b>Evento:</b></span><br>{{order.event.name}}</p>
    <p><span><b>Data da corrida:</b></span><br>{{order.event.start_at}} às {{order.event.largada}}</p>
    <p><span><b>Endereço:</b></span><br>{{order.event.local}}</p>
    <p><span><b>Nome do atleta:</b></span><br>{{order.client.full_name}}</p>
    <p><span><b>CPF:</b></span><br>{{order.client.cpf}}</p>
    <p><span><b>Modalidade:</b></span><br>{{order.inscription.modalidade}}</p>
    <p><span><b>Lote:</b></span><br>{{order.inscription.lote}}</p>
  </div>
  <div class="codes-bar">
    <p></p>
    <div style="text-align: center;">
      <vue-barcode v-if="id" :value="id" :options="{ displayValue: false , format: 'CODE39', width:2,}"></vue-barcode>
      <br/>
      <vue-qrcode v-if="id" :value="id" :options="{ width: 200 }"></vue-qrcode>
      <br>
      <b>Cód. para retirada: <span class="red">#{{ id }}</span></b>
    </div>
    <p class="mt-4" v-if="order.kit">
      <span><b>Entrega do Kit:</b></span>
      <br>
    </p>
    <div v-if="order.kit" v-html="order.kit"></div>

    <p class="mt-4" v-if="order.agendamento">
      <span><b>Agendamento da Retirada:</b></span>
      <br>
      {{order.agendamento}}
    </p>

  </div>

  <p class="aviso">
    <span>
      Importante: seu comprovante de inscrição é a identificação do seu kit. Portanto, é OBRIGATÓRIO tê-lo em mãos na retirada junto com o seu documento com foto.
    </span>
    <br>
  </p>
  <div style="text-align: center;">
    <img src="/assets/img/itens-mail.jpg" height="60">
  </div>
  <p class="mt-4">O Ticket Experience se enquadra e segue a Lei Geral de Proteção de Dados conforme da Lei 13.709/2018.</p>
  <p class="mt-1">Para mais informações sobre o evento ou sua inscrição, acesse nosso site: <a href="http://www.tkx.com.br/">www.tkx.com.br</a>.
    <br>
    <br>
    Obrigado pela preferência,
    <br>
    Equipe Ticket Experience
  </p>

</template>

<script>
import {defineComponent} from "vue";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueBarcode from '@chenfengyuan/vue-barcode';
import Loader from "../components/Loader";
import Orders from "../api/Orders";
import Auth from "../api/Auth";

export default defineComponent({
  name: "ImpressaoPage",
  components: {VueQrcode, VueBarcode,Loader},
  data() {
    return {
      id:"",
      order: {
        "client": {
          "id": "",
          "state": "",
          "city": "",
          "full_name": "",
          "cpf": "",
          "telephone": "",
          "cellphone": "",
          "street": "",
          "number": "",
          "neighborhood": ""
        },
        "event": {
          "id": "",
          "state_id": "",
          "city_id": "",
          "name": "",
          "image": "",
          "start_at": "",
          "largada": "",
          "local": ""
        },
        "kit": "",
        "inscription": {
          "modalidade": "",
          "lote": "",
          "kit": "",
          "tamanho": ""
        }
      },
      loading:true,
      slug: "",
    };
  },
  methods:{
    get_infos(){
      Orders.get_events(this.id,Auth.token()).then((request)=>{
        if(request.status == 200){
          this.order = request.data;
          this.loading = false;
          setTimeout(()=>{
            window.print();
          },400);
        }
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.get_infos();
  },
  updated() {
    if(this.id != this.$route.params.id){
      this.id = this.$route.params.id;
      this.loading = true;
      this.get_infos();
    }
  }
});
</script>

<style scoped>
.aviso{
  clear:both;
  font-size:14px;
  text-align:center;
  padding:20px 0 0 0;
}
.aviso span{
  color:#d31f28 !important;
  font-size:14px;
}
.mt-4 {
  margin-top: 30px;
}

.evento-info img {
  margin-bottom: 30px;
}

.red {
  color: #d31f28;
}

.codes-bar {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  float: left;
  display: inline-block;
}

p span {
  font-size: 20px;
  color: #d31f28;
}

p {
  margin-bottom: 10px;
  line-height: 24px;
}

.evento-info {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
  float: left;
  display: inline-block;
}
</style>
